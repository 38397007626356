body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
 * Retrieves map keys from nested maps.
 */
/**
 * Retrieves color variants from variation map.
 * color: cv(action, light); // color: #96bafa;
 */
 @font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 300;
  src: url("/font/ProximaNova-Light.woff2") format("woff2"), url("/font/ProximaNova-Light.woff") format("woff"); }

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: normal;
  src: url("/font/ProximaNova-Regular.woff2") format("woff2"), url("/font/ProximaNova-Regular.woff") format("woff"); }

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  src: url("/font/ProximaNova-Semibold.woff2") format("woff2"), url("/font/ProximaNova-Semibold.woff") format("woff"); }

html {
  box-sizing: border-box; }

* {
  box-sizing: inherit; }
  *::before, *::after {
    box-sizing: inherit; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }
  blockquote::before, blockquote::after,
  q::before,
  q::after {
    content: '';
    content: none; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

.u-visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0; }

body {
  color: #5c6971;
  font-family: "Proxima Nova", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-feature-settings: 'lnum', 'pnum'; }

a {
  color: #1662dd; }
  a:hover {
    color: #124a94; }
  a:visited {
    color: #5A13B4; }
  a[target='_blank']:not([href^='mailto:'])::after {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-left: 0.5ch;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M13.5%2010.5V13C13.5%2013.3%2013.3%2013.5%2013%2013.5H3C2.7%2013.5%202.5%2013.3%202.5%2013V3C2.5%202.7%202.7%202.5%203%202.5H5.5%22%20stroke%3D%22%231662DD%22%20stroke-miterlimit%3D%2210%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M8%208L14%202%22%20stroke%3D%22%231662DD%22%20stroke-miterlimit%3D%2210%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M9.5%201.5H14.5%22%20stroke%3D%22%231662DD%22%20stroke-miterlimit%3D%2210%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M14.5%206.5V1.5%22%20stroke%3D%22%231662DD%22%20stroke-miterlimit%3D%2210%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    vertical-align: middle; }

abbr {
  border-bottom: 1px dashed #1662dd; }

em {
  font-style: italic; }

strong {
  font-weight: 600; }

sup {
  font-size: 0.76557rem;
  line-height: 1;
  vertical-align: super; }

blockquote {
  margin: 0 0 1.5rem;
  padding: 0 0 0 0.75em;
  border-left: 2px solid #cdd1d3; }

p {
  max-width: 50ch;
  margin-bottom: 1.5rem; }
  p:last-child {
    margin-bottom: 0; }

ul {
  margin-bottom: 1.5rem;
  list-style-type: disc; }
  p + ul {
    margin-top: -0.75rem; }
  ul:last-child {
    margin-bottom: 0; }

li {
  max-width: 50ch;
  margin-bottom: 0.75rem; }
  li:last-child {
    margin-bottom: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1.5rem 0;
  color: #2f3f4a;
  line-height: 1.25; }

h1 {
  font-size: 2.54717rem;
  font-weight: 300; }

h2 {
  font-size: 1.70621rem;
  font-weight: 600; }

h3 {
  font-size: 1.30622rem; }

h4 {
  font-size: 1rem;
  font-weight: 600; }

h5 {
  font-size: 1rem;
  font-weight: 300; }

h6 {
  color: #899298;
  font-size: 0.87497rem;
  font-weight: 600; }

.legalese {
  color: #899298;
  font-size: 0.76557rem; }

.button {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0.75rem 1.5rem;
  transition-property: color, background-color, border-color, transform;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
  border: 1px solid transparent;
  border-radius: 3px;
  background-color: #1662dd;
  color: #ffffff;
  font-family: "Proxima Nova", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1; }
  .button:hover, .button.is-button-hover {
    border-color: #124a94;
    background-color: #124a94; }
  .button:active {
    transform: scale(0.95); }
  .button:focus, .button.is-button-focus {
    outline: 0; }
    .button:focus::before, .button.is-button-focus::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% + 0.75rem);
      height: calc(100% + 0.75rem);
      transform: translate(-50%, -50%);
      border: 2px dotted #5c6971;
      border-radius: calc(0.375rem + 3px - 2px); }
  .button:disabled {
    border-color: transparent;
    background-color: #b7bcc0;
    pointer-events: none; }

.is-button-secondary {
  border-color: #1662dd;
  background-color: #ffffff;
  color: #1662dd; }
  .is-button-secondary:hover, .is-button-secondary.is-button-hover {
    border-color: #124a94;
    background-color: #ffffff;
    color: #124a94; }
  .is-button-secondary:disabled {
    border-color: #b7bcc0;
    background-color: #ffffff;
    color: #b7bcc0; }

.is-button-danger {
  background-color: #dd0744; }
  .is-button-danger:hover, .is-button-danger.is-button-hover {
    border-color: #b80047;
    background-color: #b80047; }
  .is-button-danger.is-button-secondary {
    border-color: #dd0744;
    background-color: #ffffff;
    color: #dd0744; }
    .is-button-danger.is-button-secondary:hover, .is-button-danger.is-button-secondary.is-button-hover {
      border-color: #b80047;
      background-color: #ffffff;
      color: #b80047; }
    .is-button-danger.is-button-secondary:disabled {
      border-color: #b7bcc0;
      background-color: #ffffff;
      color: #b7bcc0; }

.top-bar .button, .card--footer .button, .form--header .button {
  padding: 0.375rem 1.5rem; }

/* stylelint-disable-next-line no-descending-specificity */
.button + .button,
o-button + .button, .button +
o-button,
o-button +
o-button {
  margin-left: 0.75rem; }

.form:not([data-readonly]) .form--header .button {
  display: none; }

.callout {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 50ch;
  margin: 0 0 1.5rem;
  padding: 1.5rem 1.5rem 1.5rem calc(1.5rem + 3px);
  border: 1px solid #cdd1d3;
  border-radius: 3px; }
  .callout::before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    width: 3px;
    border-radius: 3px 0 0 3px;
    background-color: #ebd551; }

.callout--icon {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem; }

.callout--content {
  font-size: 1rem; }
  .callout--content ul {
    list-style-position: inside; }

.callout--title {
  margin-bottom: 1.5rem;
  font-size: 1.30622rem;
  font-weight: 600; }

.is-callout-error::before {
  background-color: #dd0744; }

.is-callout-error .icon--fill {
  fill: #dd0744; }

.is-callout-pending::before {
  background-color: #ebd551; }

.is-callout-pending .icon--fill {
  fill: #ebd551; }

.is-callout-warning::before {
  background-color: #ebd551; }

.is-callout-warning .icon--fill {
  fill: #ebd551; }

.is-callout-help::before {
  background-color: #1662dd; }

.is-callout-help .icon--fill {
  fill: #1662dd; }

.card {
  display: block;
  position: relative;
  align-self: start;
  max-width: 50ch;
  border: 1px solid #cdd1d3;
  border-radius: 3px; }

.card--state {
  margin: -1px -1px 0;
  padding: 0.375rem 1.5rem;
  border-radius: 3px 3px 0 0;
  color: #ffffff;
  font-size: 0.76557rem;
  text-align: right; }

.card--media {
  margin: -1px -1px 0;
  padding-bottom: 0.75rem;
  overflow: hidden;
  border-radius: 3px 3px 0 0; }

.card--media-image {
  width: 100%; }

.card--header {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem 1.5rem 0.75rem; }
  .card--header:first-child {
    padding-top: 1.5rem; }
  .card--header:last-child {
    padding-bottom: 1.5rem; }
  .card--state + .card--header {
    padding-top: 1.5rem; }

.card--header-icon {
  flex-basis: auto;
  height: 3rem;
  margin-right: 0.75rem;
  line-height: 1; }
  .card--header-icon svg {
    height: 100%; }

.card--header-main {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0; }

.card--meta {
  color: #899298;
  font-size: 0.87497rem; }

.card--kebab {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem; }

.card--title {
  margin: 0 0 0.375rem 0;
  font-size: 1.30622rem;
  font-weight: 600;
  line-height: 1.25; }
  .card--title:only-child {
    margin-bottom: 0; }

.card--main {
  padding: 0.75rem 1.5rem 0.75rem;
  color: #5c6971; }
  .card--main:first-child {
    padding-top: 1.875rem; }
  .card--main:last-child {
    padding-bottom: 1.5rem; }

.card--footer {
  padding: 0.75rem 1.5rem 1.5rem; }

.card--actions {
  display: flex;
  justify-content: flex-end; }

.card--link {
  color: #1662dd; }

.is-card-action .card--state {
  background: #1662dd; }

.is-card-error .card--state {
  background: #dd0744; }

.is-card-clickable {
  transition: box-shadow 200ms linear;
  border: 0;
  box-shadow: 0 3px 6px rgba(47, 63, 74, 0.2);
  text-decoration: none;
  cursor: pointer; }
  .is-card-clickable:hover {
    box-shadow: 0 6px 24px rgba(47, 63, 74, 0.2); }
  .is-card-clickable:active {
    background: #fafafa; }

.card--list {
  display: grid;
  grid-auto-rows: minmax(0, auto);
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(calc(27ch), 1fr)); }

/* stylelint-disable no-descending-specificity, max-nesting-depth */
.checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0; }
  .checkbox:checked + .checkbox--label::after {
    opacity: 1; }
  .checkbox:focus + .checkbox--label::before {
    border-color: #1662dd; }
  .checkbox:disabled + .checkbox--label {
    opacity: 0.5;
    pointer-events: none; }
    .checkbox:disabled + .checkbox--label::before {
      border-color: #cdd1d3; }
  .checkbox:disabled:checked + .checkbox--label::after {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229%22%20height%3D%228%22%20fill%3D%22none%22%3E%0A%20%20%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h9v8H0z%22%2F%3E%0A%20%20%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h9v8H0z%22%2F%3E%0A%20%20%3Cpath%20fill%3D%22%23B7BCC0%22%20d%3D%22M.208363%205.16583c-.3072892-.28911-.268878-.75891.076823-1.01188.307289-.28911.806634-.25297%201.075514.07228l1.7285%201.87921L8.00583.359379c.61458-.722775%201.3828-.252971.76823.505943L3.7806%207.69555c-.03841.03614-.07682.07227-.11523.10841-.3457.28911-.80664.25297-1.11392-.07227L.208363%205.16583z%22%2F%3E%0A%3C%2Fsvg%3E%0A"); }
  .checkbox[data-invalid] + .checkbox--label::before {
    border-color: #dd0744; }
  .checkbox[data-invalid] + .checkbox--label::after {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229%22%20height%3D%228%22%20fill%3D%22none%22%3E%0A%20%20%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h9v8H0z%22%2F%3E%0A%20%20%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h9v8H0z%22%2F%3E%0A%20%20%3Cpath%20fill%3D%22%23C50046%22%20d%3D%22M.208363%205.16583c-.3072892-.28911-.268878-.75891.076823-1.01188.307289-.28911.806634-.25297%201.075514.07228l1.7285%201.87921L8.00583.359379c.61458-.722775%201.3828-.252971.76823.505943L3.7806%207.69555c-.03841.03614-.07682.07227-.11523.10841-.3457.28911-.80664.25297-1.11392-.07227L.208363%205.16583z%22%2F%3E%0A%3C%2Fsvg%3E%0A"); }

.checkbox--label {
  display: block;
  position: relative;
  padding-left: 1.375em;
  font-size: 1rem;
  font-weight: 400;
  text-align: left; }
  .checkbox--label::before, .checkbox--label::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 1em;
    height: 1em;
    transition-property: border-color, background, opacity;
    transition-duration: 200ms;
    transition-timing-function: linear;
    border: 1px solid transparent;
    border-radius: 3px; }
  .checkbox--label::before {
    transform: translateY(-50%);
    border-color: #cdd1d3; }
  .checkbox--label::after {
    transform: translateY(-50%) scale(1);
    opacity: 0;
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229%22%20height%3D%228%22%20fill%3D%22none%22%3E%0A%20%20%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h9v8H0z%22%2F%3E%0A%20%20%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h9v8H0z%22%2F%3E%0A%20%20%3Cpath%20fill%3D%22%231662DD%22%20d%3D%22M.208363%205.16583c-.3072892-.28911-.268878-.75891.076823-1.01188.307289-.28911.806634-.25297%201.075514.07228l1.7285%201.87921L8.00583.359379c.61458-.722775%201.3828-.252971.76823.505943L3.7806%207.69555c-.03841.03614-.07682.07227-.11523.10841-.3457.28911-.80664.25297-1.11392-.07227L.208363%205.16583z%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-repeat: no-repeat;
    background-position: center; }
  .checkbox--label:hover::before {
    border-color: #1662dd; }

.table .checkbox--label:empty {
  height: calc(1em * 1.25); }

.has-dropdown {
  position: relative; }
  .has-dropdown::after {
    content: '\25E2';
    position: absolute;
    top: 50%;
    right: 1.125rem;
    transform: translateY(-75%) translateX(200%) rotate(45deg);
    font-size: 0.375rem; }

.dropdown {
  display: none;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: calc(100%);
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #e4e5e7;
  border-radius: 3px;
  opacity: 0;
  background: #ffffff;
  box-shadow: 2px 0 20px 0 rgba(47, 63, 74, 0.1);
  line-height: 1.5; }
  .dropdown::before {
    content: '';
    position: absolute;
    top: -0.375rem;
    left: 0;
    width: 100%;
    padding-top: 0.375rem; }
  .dropdown:hover, .dropdown:focus,
  .has-dropdown:hover > .dropdown,
  .has-dropdown:focus-within > .dropdown {
    display: block;
    visibility: visible;
    opacity: 1; }

.dropdown--desc {
  min-width: 25ch;
  margin-bottom: 0.75rem;
  color: #899298;
  font-size: 0.87497rem;
  font-weight: 400;
  white-space: normal; }
  .dropdown--desc:last-child {
    margin-bottom: 0; }

.dropdown--header {
  border-bottom: 1px solid #e4e5e7; }
  .dropdown--header .dropdown--header-link {
    display: block;
    font-size: 0.87497rem;
    text-decoration: none; }

.dropdown--main {
  display: flex; }

.dropdown--header,
.dropdown--main {
  padding: 1.5rem; }
  .dropdown--header:first-child::after, .dropdown--header:first-child::before,
  .dropdown--main:first-child::after,
  .dropdown--main:first-child::before {
    content: '';
    position: absolute;
    bottom: calc(100% + 1px);
    left: 50%;
    width: 0.75rem;
    height: 0.75rem;
    transform: translateX(-50%) translateY(50%) rotate(45deg);
    border: solid transparent;
    border-width: 1px 0 0 1px; }
  .dropdown--header:first-child::after,
  .dropdown--main:first-child::after {
    border-color: #e4e5e7;
    background-color: #ffffff; }
  .dropdown--header:first-child::before,
  .dropdown--main:first-child::before {
    z-index: -1;
    box-shadow: 2px 0 20px 0 rgba(47, 63, 74, 0.1); }

.dropdown--list {
  margin: 0;
  list-style-type: none; }
  .dropdown--list:not(:first-child) {
    margin-left: 0.75rem; }

.dropdown--item {
  margin-bottom: 0.75rem; }

.dropdown--link {
  display: block;
  color: #2f3f4a;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap; }
  .dropdown--link:hover, .dropdown--link:focus {
    color: #1662dd; }

.navigation--item .dropdown {
  top: calc(100% + 0.75rem); }
  .navigation--item .dropdown::before {
    top: -1.125rem;
    padding-top: 1.125rem; }

.fieldset {
  margin: 0 0 1.5rem; }
  .fieldset:last-child {
    margin: 0; }

.form {
  max-width: calc(50ch + 1.5rem + 1.5rem + 2px);
  padding: 1.5rem;
  border: 1px solid #cdd1d3;
  border-radius: 3px; }

.form--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 1.5rem; }

.form--title {
  margin: 0;
  padding: 0;
  color: #2f3f4a;
  font-size: 1.70621rem;
  font-weight: 600;
  line-height: 1.25; }

.form--actions {
  display: flex;
  justify-content: flex-end; }
  .form--actions:not(:last-child) {
    margin-bottom: 0.75rem; }
  [data-readonly] .form--actions {
    display: none; }

.form--footer {
  font-size: 0.87497rem; }

.field--hint,
.field--error {
  font-size: 0.87497rem; }

.field--hint {
  margin-bottom: 0.375rem;
  color: #899298; }

.field--error {
  margin-top: 0.375rem;
  color: #dd0744; }

.fieldset--pair {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  .fieldset--pair .number-input,
  .fieldset--pair .text-input,
  .fieldset--pair .select-input {
    width: calc(50% - 0.75rem); }

.fieldset-flex {
  display: flex;
  flex-direction: column;
  max-width: 50ch; }
  .fieldset-flex .label,
  .fieldset-flex .input-legend {
    order: 1; }
  .fieldset-flex .number-input,
  .fieldset-flex .text-input,
  .fieldset-flex .select-input,
  .fieldset-flex .input--unavailable,
  .fieldset-flex .fieldset--pair {
    order: 3; }
  .fieldset-flex .input--unavailable {
    margin: 0; }
  .fieldset-flex .field--hint {
    order: 2; }
  .fieldset-flex .field--error {
    order: 4; }

.input-legend {
  margin: 0 0 0.375rem;
  color: #2f3f4a;
  font-size: 1rem;
  font-weight: 600; }

.group-legend {
  margin: 0 0 0.75rem;
  color: #2f3f4a;
  font-size: 1.30622rem;
  font-weight: 600; }

.modal .form {
  padding: 0;
  border: 0; }

.modal .form--header {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0; }

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.375rem;
  color: #2f3f4a;
  font-size: 1rem;
  font-weight: 600; }
  .password-input ~ .label::after {
    content: '';
    width: 1rem;
    height: 1rem;
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(30%) sepia(91%) saturate(3619%) hue-rotate(211deg) brightness(91%) contrast(90%); }
  .password-input[type='password'] ~ .label::after {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20100%20125%22%3E%0A%20%20%3Cpath%20d%3D%22M92.6%2043.9c-14.2-14.1-29-21.1-43.9-20.7-19.7.4-34.5%2013.3-41.5%2020.9-2.9%203.1-2.9%208.6%200%2011.8%207%207.6%2021.8%2020.5%2041.5%2021h1c14.6%200%2029.1-7%2042.9-20.8%201.5-1.5%202.4-3.7%202.4-6.1%200-2.4-.9-4.6-2.4-6.1zM50%2070.9c-11.5%200-20.9-9.4-20.9-20.9%200-11.5%209.4-20.9%2020.9-20.9%2011.5%200%2020.9%209.4%2020.9%2020.9%200%2011.5-9.4%2020.9-20.9%2020.9zm0-34.8c-7.7%200-13.9%206.2-13.9%2013.9S42.3%2063.9%2050%2063.9%2063.9%2057.7%2063.9%2050%2057.7%2036.1%2050%2036.1zM50%2059c-4.9%200-9-4-9-9%200-1.1.9-2%202-2s2%20.9%202%202c0%202.7%202.2%205%205%205%201.1%200%202%20.9%202%202s-.9%202-2%202z%22%2F%3E%0A%3C%2Fsvg%3E%0A"); }
  .password-input[type='text'] ~ .label::after {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20100%20125%22%3E%0A%20%20%3Cpath%20d%3D%22M95%2049.3c0%202.4-.9%204.6-2.4%206.1-13.9%2013.8-28.3%2020.8-42.9%2020.8h-1c-4-.1-7.7-.7-11.3-1.6L43%2069c2.2.8%204.6%201.2%207.1%201.2%2011.5%200%2020.9-9.4%2020.9-20.9%200-2.5-.5-4.9-1.2-7l9.9-9.9c4.4%203%208.8%206.6%2013.1%2010.9%201.3%201.4%202.2%203.6%202.2%206zM50%2063.2c7.7%200%2013.9-6.2%2013.9-13.9%200-.4%200-.9-.1-1.3L48.7%2063.2H50zm34.6-44.4L18.1%2085.3c-.5.3-1%20.4-1.5.4-.8%200-1.5-.3-2.1-.9-1.2-1.2-1.2-3.1%200-4.2l10.9-10.9C17.3%2065.2%2011%2059.4%207.2%2055.2c-2.9-3.1-2.9-8.7%200-11.8%207-7.7%2021.8-20.5%2041.5-21%206.7-.2%2013.4%201.2%2020%204l11.2-11.2c1.2-1.2%203.1-1.2%204.2%200%201%201%201.1%202.5.5%203.6zM45.1%2050c0-.2-.1-.5-.1-.7%200-1.1-.9-2-2-2s-2%20.9-2%202c0%201.4.3%202.7.9%203.8l3.2-3.1zm17.4-17.4C59%2030%2054.7%2028.4%2050%2028.4c-11.5%200-20.9%209.4-20.9%2020.9%200%204.7%201.6%209%204.2%2012.5l5-5c-1.4-2.2-2.2-4.7-2.2-7.5%200-7.7%206.2-13.9%2013.9-13.9%202.8%200%205.3.8%207.5%202.2l5-5z%22%2F%3E%0A%3C%2Fsvg%3E%0A"); }
  .text-input:disabled ~ .label,
  .number-input:disabled ~ .label {
    color: rgba(92, 105, 113, 0.5);
    pointer-events: none; }
  .text-input:optional ~ .label::after,
  .number-input:optional ~ .label::after {
    content: 'Optional';
    padding: 0 0.375em;
    color: #899298;
    font-size: 0.87497rem;
    font-weight: 400; }

.meter {
  width: 100%;
  height: 0.375rem;
  border: 0;
  border-radius: 0.125rem;
  background: #e4e5e7;
  box-shadow: none;
  appearance: meter; }

.meter::-webkit-meter-bar {
  border: 0;
  border-radius: 0.125rem;
  background: #e4e5e7;
  box-shadow: none; }

.meter::-webkit-meter-optimum-value,
.meter::-webkit-meter-suboptimum-value,
.meter::-webkit-meter-even-less-good-value {
  border-radius: 0.125rem; }

.meter::-webkit-meter-optimum-value {
  background: #00d1b3; }

.meter::-webkit-meter-suboptimum-value {
  background: #ebd551; }

.meter::-webkit-meter-even-less-good-value {
  background: #dd0744; }

.meter::-moz-meter-bar {
  border: 0;
  border-radius: 0.125rem; }

.meter:-moz-meter-optimum::-moz-meter-bar {
  background: #00d1b3; }

.meter:-moz-meter-sub-optimum::-moz-meter-bar {
  background: #ebd551; }

.meter:-moz-meter-sub-sub-optimum::-moz-meter-bar {
  background: #dd0744; }

.meter--value {
  font-weight: 600; }

.meter--label {
  display: block; }

.meter--fallback {
  display: block;
  width: 100%;
  height: 0.375rem;
  margin-top: 0.75rem;
  border-radius: 0.125rem;
  background: #e4e5e7; }

.meter--fallback > .meter--fallback--value {
  display: block;
  height: inherit;
  border-radius: 0.125rem;
  background-size: 100% 100%;
  text-indent: -9999px; }

.meter--optimum-value {
  background: #00d1b3; }

.meter--suboptimum-value {
  background: #ebd551; }

.meter--subsuboptimum-value {
  background: #dd0744; }

.modal--overlay {
  display: flex;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background: rgba(47, 63, 74, 0.9); }

.modal--dialog {
  position: relative;
  max-width: calc(50ch + 1.5rem + 1.5rem);
  max-height: calc(100vh - 3rem);
  padding: 1.875rem 1.5rem 0;
  overflow-y: auto;
  border-radius: 3px;
  background-color: #ffffff; }
  .modal--dialog::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.375rem;
    background-color: #1662dd; }

.modal--header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

.modal--close {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  border: 0;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2219%22%20height%3D%2219%22%20fill%3D%22none%22%3E%0A%20%20%3Cpath%20stroke%3D%22%232F3F4A%22%20stroke-width%3D%221.5%22%20d%3D%22M1%2018L18%201M18%2018L1%201%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: center; }

.modal--title {
  margin-top: 0;
  margin-bottom: 0;
  color: #2f3f4a;
  font-size: 1.30622rem;
  font-weight: 600;
  line-height: 1.25; }

.modal--content {
  padding: 1.5rem 0 3rem;
  font-size: 1rem; }

.modal--footer {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1.5rem; }

.is-modal-danger .modal--dialog::before {
  background-color: #dd0744; }

@keyframes overlay-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes overlay-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes dialog-in {
  from {
    transform: scale(0.7);
    opacity: 0; }
  to {
    transform: scale(1);
    opacity: 1; } }

@keyframes dialog-out {
  from {
    transform: scale(1);
    opacity: 1; }
  to {
    transform: scale(0.7);
    opacity: 0; } }

.modal {
  display: none; }
  .modal.is-open {
    display: block; }
  .modal[aria-hidden='false'] .modal--overlay {
    animation: overlay-in 200ms linear; }
  .modal[aria-hidden='false'] .modal--dialog {
    animation: dialog-in 200ms linear; }
  .modal[aria-hidden='true'] .modal--overlay {
    animation: overlay-out 200ms linear; }
  .modal[aria-hidden='true'][aria-hidden='true'] .modal--dialog {
    animation: dialog-out 200ms linear; }

.navigation {
  display: flex; }

.navigation--list {
  display: flex;
  align-items: center;
  margin: 0;
  padding-left: 0;
  list-style: none; }

.navigation--item {
  display: block;
  margin: 0;
  line-height: 1; }

.navigation--category,
.navigation--link {
  display: inline-block;
  padding: 0.375rem 1.125rem; }

.navigation--category {
  cursor: default; }
  .navigation--category:hover {
    color: #2f3f4a; }

.navigation--link {
  color: #5c6971;
  text-decoration: none; }
  .navigation--link:hover,
  .navigation--item:hover .navigation--link, .navigation--link.is-link-active {
    color: #1662dd; }

.number-input {
  display: block;
  position: relative;
  width: 100%;
  max-width: 50ch;
  margin: 0;
  padding: 0.75rem;
  transition-property: border-color, background-color;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
  border: 1px solid #cdd1d3;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: none;
  color: #5c6971;
  font-family: "Proxima Nova", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.25; }
  .number-input:hover, .number-input:focus {
    border-color: #1662dd; }
  .number-input:disabled {
    opacity: 0.5;
    pointer-events: none; }
  .number-input[readonly] {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    border: 0;
    background-color: transparent; }
  .number-input[data-invalid] {
    border-color: #dd0744;
    background-color: #fee6ea; }
  .number-input::placeholder {
    color: #899298; }
  .number-input[readonly] {
    appearance: textfield; }

/* stylelint-disable no-descending-specificity, max-nesting-depth */
.radio {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0; }
  .radio:checked + .radio--label::after {
    background-color: #1662dd; }
  .radio:focus + .radio--label::before {
    border-color: #1662dd; }
  .radio:disabled + .radio--label {
    opacity: 0.5;
    pointer-events: none; }
    .radio:disabled + .radio--label::before {
      border-color: #cdd1d3; }
  .radio:disabled:checked + .radio--label::after {
    background-color: #b7bcc0; }
  .radio[data-invalid] + .radio--label::before {
    border-color: #dd0744; }
  .radio[data-invalid] + .radio--label:hover::after {
    background-color: rgba(221, 7, 68, 0.5); }
  .radio[data-invalid]:checked + .radio--label::after {
    background-color: #dd0744; }

.radio--label {
  display: block;
  position: relative;
  padding-left: 1.375em;
  font-size: 1rem;
  font-weight: 400;
  text-align: left; }
  .radio--label::before, .radio--label::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 1em;
    height: 1em;
    transition-property: border-color, background;
    transition-duration: 200ms;
    transition-timing-function: linear;
    border: 1px solid transparent;
    border-radius: 50%; }
  .radio--label::before {
    transform: translateY(-50%);
    border-color: #cdd1d3; }
  .radio--label::after {
    transform: translateY(-50%) scale(0.571);
    background-color: rgba(22, 98, 221, 0); }
  .radio--label:hover::before {
    border-color: #1662dd; }

/* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
.table {
  width: auto;
  margin: 0 0 1.5rem;
  border-spacing: 0;
  border-collapse: collapse;
  line-height: 1.25; }
  .table:only-child {
    margin-bottom: 0; }
  .table caption {
    margin-bottom: 0.75rem;
    color: #2f3f4a;
    font-size: 1.30622rem;
    font-weight: 600;
    text-align: left; }
  .table td,
  .table th {
    max-width: 50ch;
    padding: 0.75rem;
    text-align: left;
    vertical-align: top; }
  .table th {
    color: #2f3f4a;
    font-weight: 600; }
  .table thead th {
    border-bottom: 1px solid #cdd1d3; }
  .table tbody [rowspan] {
    border: 0;
    border-top: 1px solid #e4e5e7;
    background-color: #ffffff; }
  .table tbody tr td,
  .table tbody tr th {
    border-bottom: 1px solid #e4e5e7; }
  .table tbody tr:first-child [rowspan] {
    border-top: 0; }
  .table tbody tr:last-child td,
  .table tbody tr:last-child th {
    border-bottom: 0; }
  .table[data-null] tbody {
    display: block; }
  .table[data-null] tfoot tr td {
    max-width: 100%;
    padding: 3rem 6rem;
    background: #fafafa;
    color: #899298;
    text-align: center; }
  .table .is-table-num {
    text-align: right;
    font-feature-settings: 'lnum', 'tnum'; }
  .table .is-table-date {
    white-space: nowrap; }

.table--figure {
  max-width: 100%;
  overflow-x: auto; }

.table--cell-filter {
  display: inline-block;
  margin: 0;
  padding: 0;
  transition: border-bottom-color 200ms linear;
  border: 0;
  border-bottom: 1px solid;
  border-bottom-color: #e5edfb;
  background-color: transparent;
  color: #5c6971;
  font-family: "Proxima Nova", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  text-align: left; }
  .table--cell-filter:hover {
    border-bottom-color: #1662dd; }

.table--cell-status {
  display: table;
  padding-top: 0.375rem;
  font-size: 0.87497rem; }

.table--cell-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .table--cell-list li {
    margin: 0 0 0.375rem;
    padding: 0; }

@media only screen and (max-width: 75ch) {
  .is-table-inessential {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    border: 0; } }

@media only screen and (max-width: 75ch) {
  .is-table-linear table,
  .is-table-linear caption,
  .is-table-linear tbody,
  .is-table-linear tr,
  .is-table-linear th {
    display: block;
    text-align: left; }
  .is-table-linear td {
    display: flex;
    justify-content: space-between;
    padding: 0.375rem 0.75rem; }
    .is-table-linear td:first-child {
      padding: 1.5rem 0.75rem 0.375rem;
      color: #2f3f4a; }
    .is-table-linear td:last-child {
      padding: 0.375rem 0.75rem 1.5rem;
      border-bottom: 1px solid #cdd1d3; }
  .is-table-linear thead,
  .is-table-linear th:empty,
  .is-table-linear td:empty {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    border: 0; }
  .is-table-linear tr:last-child td {
    border-bottom: 0; }
  .is-table-linear [data-title]::before {
    content: attr(data-title);
    display: inline-block;
    padding-right: 1.5rem;
    font-weight: 600; }
  .is-table-linear caption ~ tbody tr:first-child td:first-child {
    padding-top: 0.75rem; }
  .is-table-linear .is-table-num {
    text-align: left; } }

.text-input {
  display: block;
  position: relative;
  width: 100%;
  max-width: 50ch;
  margin: 0;
  padding: 0.75rem;
  transition-property: border-color, background-color;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
  border: 1px solid #cdd1d3;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: none;
  color: #5c6971;
  font-family: "Proxima Nova", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.25; }
  .text-input:hover, .text-input:focus {
    border-color: #1662dd; }
  .text-input:disabled {
    opacity: 0.5;
    pointer-events: none; }
  .text-input[readonly] {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    border: 0;
    background-color: transparent; }
  .text-input[data-invalid] {
    border-color: #dd0744;
    background-color: #fee6ea; }
  .text-input::placeholder {
    color: #899298; }

.text-area {
  min-width: 1.5rem;
  max-width: 50ch;
  min-height: 3rem; }

.tooltip {
  display: block;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  padding: 0.375rem 0.75rem;
  transition: opacity 200ms linear 1s;
  border: 1px solid #2f3f4a;
  border-radius: 3px;
  opacity: 0;
  background: #2f3f4a;
  color: #fafafa;
  font-size: 0.87497rem;
  white-space: nowrap;
  pointer-events: none; }
  .tooltip::after, .tooltip::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: solid transparent; }
  .tooltip::after {
    border-width: calc(0.375rem - 2px); }
  .tooltip::before {
    border-width: 0.375rem; }
  *:focus + .tooltip,
  .has-tooltip:hover .tooltip,
  .has-tooltip:focus .tooltip {
    visibility: visible;
    opacity: 1; }

.is-tooltip-top {
  bottom: calc(100% + 0.75rem);
  left: 50%;
  transform: translateX(-50%); }
  .is-tooltip-top::after, .is-tooltip-top::before {
    top: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .is-tooltip-top::after {
    border-top-color: #2f3f4a; }
  .is-tooltip-top::before {
    border-top-color: #2f3f4a; }

.is-tooltip-right {
  top: 50%;
  left: calc(100% + 0.75rem);
  transform: translateY(-50%); }
  .is-tooltip-right::after, .is-tooltip-right::before {
    top: 50%;
    right: 100%;
    transform: translateY(-50%); }
  .is-tooltip-right::after {
    border-right-color: #2f3f4a; }
  .is-tooltip-right::before {
    border-right-color: #2f3f4a; }

.is-tooltip-bottom {
  top: calc(100% + 0.75rem);
  left: 50%;
  transform: translateX(-50%); }
  .is-tooltip-bottom::after, .is-tooltip-bottom::before {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .is-tooltip-bottom::after {
    border-bottom-color: #2f3f4a; }
  .is-tooltip-bottom::before {
    border-bottom-color: #2f3f4a; }

.is-tooltip-left {
  top: 50%;
  right: calc(100% + 0.75rem);
  transform: translateY(-50%); }
  .is-tooltip-left::after, .is-tooltip-left::before {
    top: 50%;
    left: 100%;
    transform: translateY(-50%); }
  .is-tooltip-left::after {
    border-left-color: #2f3f4a; }
  .is-tooltip-left::before {
    border-left-color: #2f3f4a; }

.has-tooltip {
  display: inline-block;
  position: relative; }
  .has-tooltip svg {
    display: block; }

@media only screen and (max-width: 75ch) {
  .tooltip {
    display: block;
    visibility: hidden;
    position: fixed;
    z-index: 1;
    bottom: 0;
    width: 100vw;
    border: 0;
    border-radius: 0;
    opacity: 0;
    font-size: 0.87497rem;
    white-space: wrap;
    pointer-events: none; }
    .tooltip::after, .tooltip::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border: solid transparent; }
    .tooltip::after {
      border-width: calc(0.375rem - 2px); }
    .tooltip::before {
      border-width: 0.375rem; }
  .is-tooltip-top,
  .is-tooltip-right,
  .is-tooltip-bottom,
  .is-tooltip-left {
    top: 0;
    right: unset;
    bottom: unset;
    left: 0;
    transform: none; }
    .is-tooltip-top::after, .is-tooltip-top::before,
    .is-tooltip-right::after,
    .is-tooltip-right::before,
    .is-tooltip-bottom::after,
    .is-tooltip-bottom::before,
    .is-tooltip-left::after,
    .is-tooltip-left::before {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border: 0; } }

.top-bar {
  display: flex;
  padding: 0.75rem 3rem;
  border-bottom: 1px solid #e4e5e7; }

.top-bar--logo {
  height: 1.1429rem;
  fill: #1662dd; }

.top-bar--actions {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end; }

/* stylelint-disable declaration-no-important, selector-class-pattern, max-nesting-depth, selector-max-compound-selectors, selector-no-qualifying-type, no-descending-specificity */
.selectize-input {
  display: block;
  position: relative;
  width: 100%;
  max-width: 50ch;
  margin: 0;
  padding: 0.75rem;
  transition-property: border-color, background-color;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
  border: 1px solid #cdd1d3;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: none;
  color: #5c6971;
  font-family: "Proxima Nova", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.25;
  z-index: 1;
  overflow: hidden; }
  .selectize-input:hover, .selectize-input:focus {
    border-color: #1662dd; }
  .selectize-input:disabled {
    opacity: 0.5;
    pointer-events: none; }
  .selectize-input[readonly] {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    border: 0;
    background-color: transparent; }
  .selectize-input[data-invalid] {
    border-color: #dd0744;
    background-color: #fee6ea; }
  .selectize-input::placeholder {
    color: #899298; }
  .selectize-input > * {
    display: inline-block;
    zoom: 1;
    vertical-align: baseline; }
  .selectize-input.disabled,
  .selectize-input.disabled * {
    pointer-events: none; }
  .selectize-input::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    right: 0.75rem;
    width: 0;
    height: 0;
    transform: translateY(-50%);
    border-width: 5px 5px 0;
    border-style: solid;
    border-color: #5c6971 transparent transparent; }
  .selectize-input.dropdown-active {
    border-radius: 3px 3px 0 0; }
    .selectize-input.dropdown-active::after {
      border-width: 0 5px 5px;
      border-color: transparent transparent #5c6971; }
  .selectize-input > input {
    display: inline-block !important;
    width: 100% !important;
    max-width: 100% !important;
    min-height: 0 !important;
    max-height: none !important;
    margin: 0 2px 0 0;
    padding: 0;
    border: 0;
    background: none !important;
    box-shadow: none !important;
    font-size: inherit;
    line-height: inherit !important;
    text-indent: 0 !important; }
    .selectize-input > input::placeholder {
      color: #899298;
      font-size: 1rem; }
  .selectize-input.full > input, .selectize-input.has-items > input {
    width: 0 !important; }

.selectize-input,
.selectize-control.single .selectize-input.input-active {
  display: inline-block;
  cursor: text; }

.selectize-control {
  position: relative; }
  .selectize-control .selectize-input.disabled {
    opacity: 0.5;
    background-color: #fafafa; }
  .selectize-control.multi .selectize-input {
    padding-top: calc(0.75rem - (0.375rem / 2) - 1px);
    padding-bottom: calc(0.75rem - (0.375rem / 2) - 0.375rem - 1px); }
    .selectize-control.multi .selectize-input > input {
      margin: 0 0 0.375rem 0;
      padding: 0.1875rem 0 0.1875rem;
      border: 1px solid transparent; }
    .selectize-control.multi .selectize-input > .item {
      margin: 0 0.375rem 0.375rem 0;
      padding: 0.1875rem 0 0.1875rem 0.75rem;
      border: 1px solid #cdd1d3;
      border-radius: 1em;
      background-color: #ffffff;
      cursor: default; }
      .selectize-control.multi .selectize-input > .item:last-child {
        margin-right: 0; }
      .selectize-control.multi .selectize-input > .item.active {
        border-color: #1662dd; }
    .selectize-control.multi .selectize-input.disabled > div,
    .selectize-control.multi .selectize-input.disabled > div.active {
      border: 0 solid #ffffff;
      background: #ffffff;
      color: #7d7d7d; }
  .selectize-control.single .selectize-input.input-active,
  .selectize-control.single .selectize-input.input-active input {
    cursor: text; }
  .selectize-control.rtl .selectize-input > input {
    margin: 0 4px 0 -2px !important; }
  .selectize-control.rtl.single .selectize-input::after {
    right: auto;
    left: 15px; }
  .selectize-control.plugin-drag_drop .ui-sortable-placeholder::after {
    content: '!';
    visibility: hidden; }
  .selectize-control.plugin-drag_drop .ui-sortable-helper {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); }
  .selectize-control.plugin-drag_drop.multi > .selectize-input > .ui-sortable-placeholder {
    visibility: visible !important;
    order: 0 none !important;
    background: #f2f2f2 !important;
    background: rgba(0, 0, 0, 0.06) !important;
    box-shadow: inset 0 0 12px 4px #ffffff; }
  .selectize-control.plugin-remove_button [data-value] {
    position: relative; }
    .selectize-control.plugin-remove_button [data-value] .remove {
      display: inline-block;
      padding: 0 0.75rem;
      color: #899298;
      font-weight: 600;
      text-decoration: none;
      cursor: default; }
      .selectize-control.plugin-remove_button [data-value] .remove:hover {
        color: #2f3f4a; }
  .selectize-control.plugin-remove_button .remove-single {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 23px; }

.selectize-dropdown-header {
  position: relative;
  padding: 5px 8px;
  border-bottom: 1px solid #d0d0d0;
  border-radius: 3px 3px 0 0;
  background: #f8f8f8; }

.selectize-dropdown-header-close {
  position: absolute;
  top: 50%;
  right: 8px;
  margin-top: -12px;
  opacity: 0.4;
  color: #303030;
  font-size: 20px !important;
  line-height: 20px; }
  .selectize-dropdown-header-close:hover {
    color: #2f3f4a; }

.selectize-dropdown {
  position: absolute;
  z-index: 10;
  border: 1px solid #e4e5e7;
  border-top: 0;
  border-radius: 0 0 3px 3px;
  background: #ffffff; }
  .selectize-dropdown [data-selectable] {
    overflow: hidden; }
    .selectize-dropdown [data-selectable] .highlight {
      border-radius: 1px;
      background: rgba(125, 168, 208, 0.2); }
    .selectize-dropdown [data-selectable].option {
      opacity: 1; }
      .selectize-dropdown [data-selectable].option:hover::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid #1662dd; }
  .selectize-dropdown .option,
  .selectize-dropdown .optgroup-header,
  .selectize-dropdown .create {
    padding: 0.75rem; }
  .selectize-dropdown .option,
  .selectize-dropdown [data-disabled],
  .selectize-dropdown [data-disabled] [data-selectable].option {
    opacity: 0.5;
    cursor: inherit; }
  .selectize-dropdown .optgroup-header {
    padding: 0.75rem 0.75rem 0.375rem;
    border: 1px solid #e4e5e7;
    border-right: 0;
    border-left: 0;
    background-color: #fafafa;
    font-size: 0.87497rem;
    font-weight: 600; }
  .selectize-dropdown .option {
    position: relative;
    border-bottom: 1px solid #e4e5e7; }
    .selectize-dropdown .option:last-child {
      border-bottom: 0; }
  .selectize-dropdown .active.create {
    color: #495c68; }
  .selectize-dropdown .create {
    color: rgba(48, 48, 48, 0.5); }
  .selectize-dropdown.plugin-optgroup_columns .optgroup {
    box-sizing: border-box;
    float: left;
    border-top: 0;
    border-right: 1px solid #f2f2f2; }
    .selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
      border-right: 0; }
    .selectize-dropdown.plugin-optgroup_columns .optgroup::before {
      display: none; }
  .selectize-dropdown.plugin-optgroup_columns .optgroup-header {
    border-top: 0; }

.selectize-dropdown-content {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto; }

.page--sign-in .form {
  max-width: 35ch; }

.page--sign-in .form--actions .button {
  width: 100%; }

.modal--form {
  position: relative;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 0 20px rgba(47, 63, 74, 0.1); }
  .modal--form::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    border-top: 2px solid #124a94; }

.sample--icon {
  width: 1.5rem;
  margin-right: 1.5rem; }
  .sample--icon:last-of-type {
    margin-right: 0; }

.sample--layout {
  max-width: 50ch; }

.sample--tip {
  display: inline-block; }
  .sample--tip + .sample--tip {
    margin-left: 1.5rem; }

.sample--list {
  list-style-type: none; }

.type-sample--wrap {
  max-width: 50ch;
  overflow-x: scroll; }

.type-sample--table {
  white-space: nowrap; }

.type-sample:nth-of-type(1) .type-sample--rem::before {
  content: "0.76rem"; }

.type-sample:nth-of-type(1) .type-sample--px::before {
  content: "12px"; }

.type-sample:nth-of-type(1) .type-sample--function::before {
  content: "ms(-2)"; }

.type-sample:nth-of-type(1) .type-sample--example {
  font-size: 0.76557rem; }

.type-sample:nth-of-type(2) .type-sample--rem::before {
  content: "0.87rem"; }

.type-sample:nth-of-type(2) .type-sample--px::before {
  content: "14px"; }

.type-sample:nth-of-type(2) .type-sample--function::before {
  content: "ms(-1)"; }

.type-sample:nth-of-type(2) .type-sample--example {
  font-size: 0.87497rem; }

.type-sample:nth-of-type(3) .type-sample--rem::before {
  content: "1rem"; }

.type-sample:nth-of-type(3) .type-sample--px::before {
  content: "16px"; }

.type-sample:nth-of-type(3) .type-sample--function::before {
  content: "ms(0)"; }

.type-sample:nth-of-type(3) .type-sample--example {
  font-size: 1rem; }

.type-sample:nth-of-type(4) .type-sample--rem::before {
  content: "1.14rem"; }

.type-sample:nth-of-type(4) .type-sample--px::before {
  content: "18px"; }

.type-sample:nth-of-type(4) .type-sample--function::before {
  content: "ms(1)"; }

.type-sample:nth-of-type(4) .type-sample--example {
  font-size: 1.1429rem; }

.type-sample:nth-of-type(5) .type-sample--rem::before {
  content: "1.30rem"; }

.type-sample:nth-of-type(5) .type-sample--px::before {
  content: "21px"; }

.type-sample:nth-of-type(5) .type-sample--function::before {
  content: "ms(2)"; }

.type-sample:nth-of-type(5) .type-sample--example {
  font-size: 1.30622rem; }

.type-sample:nth-of-type(6) .type-sample--rem::before {
  content: "1.49rem"; }

.type-sample:nth-of-type(6) .type-sample--px::before {
  content: "24px"; }

.type-sample:nth-of-type(6) .type-sample--function::before {
  content: "ms(3)"; }

.type-sample:nth-of-type(6) .type-sample--example {
  font-size: 1.49288rem; }

.type-sample:nth-of-type(7) .type-sample--rem::before {
  content: "1.70rem"; }

.type-sample:nth-of-type(7) .type-sample--px::before {
  content: "27px"; }

.type-sample:nth-of-type(7) .type-sample--function::before {
  content: "ms(4)"; }

.type-sample:nth-of-type(7) .type-sample--example {
  font-size: 1.70621rem; }

.type-sample:nth-of-type(8) .type-sample--rem::before {
  content: "1.95rem"; }

.type-sample:nth-of-type(8) .type-sample--px::before {
  content: "31px"; }

.type-sample:nth-of-type(8) .type-sample--function::before {
  content: "ms(5)"; }

.type-sample:nth-of-type(8) .type-sample--example {
  font-size: 1.95003rem; }

.type-sample:nth-of-type(9) .type-sample--rem::before {
  content: "2.22rem"; }

.type-sample:nth-of-type(9) .type-sample--px::before {
  content: "36px"; }

.type-sample:nth-of-type(9) .type-sample--function::before {
  content: "ms(6)"; }

.type-sample:nth-of-type(9) .type-sample--example {
  font-size: 2.22869rem; }

.type-sample:nth-of-type(10) .type-sample--rem::before {
  content: "2.54rem"; }

.type-sample:nth-of-type(10) .type-sample--px::before {
  content: "41px"; }

.type-sample:nth-of-type(10) .type-sample--function::before {
  content: "ms(7)"; }

.type-sample:nth-of-type(10) .type-sample--example {
  font-size: 2.54717rem; }

.type-sample:nth-of-type(11) .type-sample--rem::before {
  content: "2.91rem"; }

.type-sample:nth-of-type(11) .type-sample--px::before {
  content: "47px"; }

.type-sample:nth-of-type(11) .type-sample--function::before {
  content: "ms(8)"; }

.type-sample:nth-of-type(11) .type-sample--example {
  font-size: 2.91116rem; }

.type-sample:nth-of-type(12) .type-sample--rem::before {
  content: "3.32rem"; }

.type-sample:nth-of-type(12) .type-sample--px::before {
  content: "53px"; }

.type-sample:nth-of-type(12) .type-sample--function::before {
  content: "ms(9)"; }

.type-sample:nth-of-type(12) .type-sample--example {
  font-size: 3.32716rem; }

.type-sample:nth-of-type(13) .type-sample--rem::before {
  content: "3.80rem"; }

.type-sample:nth-of-type(13) .type-sample--px::before {
  content: "61px"; }

.type-sample:nth-of-type(13) .type-sample--function::before {
  content: "ms(10)"; }

.type-sample:nth-of-type(13) .type-sample--example {
  font-size: 3.80261rem; }

.type-sample--300,
.type-sample--400,
.type-sample--600 {
  font-size: 1.70621rem;
  line-height: 1.25; }

.type-sample--300 {
  font-weight: 300; }

.type-sample--400 {
  font-weight: normal; }

.type-sample--600 {
  font-weight: 600; }

.conversion--huge-spacing-actual::before {
  content: "6rem"; }

.conversion--huge-spacing-px::before {
  content: "96px"; }

.sample--type-huge-spacing {
  position: relative;
  min-height: 1rem;
  margin-bottom: 1.5rem;
  padding: 0 0 0 3rem;
  font-size: 6rem; }
  .sample--type-huge-spacing::before {
    content: "ms(e-spacing)";
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.87497rem; }

.sample--spacing-huge-spacing {
  position: relative;
  width: 6rem;
  height: 6rem;
  margin: 0 0 1.5rem 0;
  border: 1px solid #2f3f4a; }

.conversion--big-spacing-actual::before {
  content: "3rem"; }

.conversion--big-spacing-px::before {
  content: "48px"; }

.sample--type-big-spacing {
  position: relative;
  min-height: 1rem;
  margin-bottom: 1.5rem;
  padding: 0 0 0 3rem;
  font-size: 3rem; }
  .sample--type-big-spacing::before {
    content: "ms(-spacing)";
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.87497rem; }

.sample--spacing-big-spacing {
  position: relative;
  width: 3rem;
  height: 3rem;
  margin: 0 0 1.5rem 0;
  border: 1px solid #2f3f4a; }

.conversion--base-spacing-actual::before {
  content: "1.5rem"; }

.conversion--base-spacing-px::before {
  content: "24px"; }

.sample--type-base-spacing {
  position: relative;
  min-height: 1rem;
  margin-bottom: 1.5rem;
  padding: 0 0 0 3rem;
  font-size: 1.5rem; }
  .sample--type-base-spacing::before {
    content: "ms(e-spacing)";
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.87497rem; }

.sample--spacing-base-spacing {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0 1.5rem 0;
  border: 1px solid #2f3f4a; }

.conversion--small-spacing-actual::before {
  content: "0.75rem"; }

.conversion--small-spacing-px::before {
  content: "12px"; }

.sample--type-small-spacing {
  position: relative;
  min-height: 1rem;
  margin-bottom: 1.5rem;
  padding: 0 0 0 3rem;
  font-size: 0.75rem; }
  .sample--type-small-spacing::before {
    content: "ms(ll-spacing)";
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.87497rem; }

.sample--spacing-small-spacing {
  position: relative;
  width: 0.75rem;
  height: 0.75rem;
  margin: 0 0 1.5rem 0;
  border: 1px solid #2f3f4a; }

.conversion--tiny-spacing-actual::before {
  content: "0.375rem"; }

.conversion--tiny-spacing-px::before {
  content: "6px"; }

.sample--type-tiny-spacing {
  position: relative;
  min-height: 1rem;
  margin-bottom: 1.5rem;
  padding: 0 0 0 3rem;
  font-size: 0.375rem; }
  .sample--type-tiny-spacing::before {
    content: "ms(y-spacing)";
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.87497rem; }

.sample--spacing-tiny-spacing {
  position: relative;
  width: 0.375rem;
  height: 0.375rem;
  margin: 0 0 1.5rem 0;
  border: 1px solid #2f3f4a; }

.sample--colors-wrap {
  /* Fallback Support */
  display: flex;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(25ch, 1fr));
  flex-wrap: wrap; }

.sample--color-list {
  overflow: hidden;
  border-radius: 3px; }

.sample--color {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 0.75rem; }

.is-sample-success-lightest {
  background: #e5fff6; }
  .is-sample-success-lightest::before, .is-sample-success-lightest::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-success-lightest::before {
    content: "success, lightest"; }
  .is-sample-success-lightest::after {
    content: "#e5fff6"; }

.is-sample-success-base {
  background: #00d1b3; }
  .is-sample-success-base::before, .is-sample-success-base::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-success-base::before {
    content: "success, base"; }
  .is-sample-success-base::after {
    content: "#00d1b3"; }

.is-sample-success-dark {
  background: #00a08e; }
  .is-sample-success-dark::before, .is-sample-success-dark::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-success-dark::before {
    content: "success, dark"; }
  .is-sample-success-dark::after {
    content: "#00a08e"; }

.is-sample-action-lightest {
  background: #e5edfb; }
  .is-sample-action-lightest::before, .is-sample-action-lightest::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-action-lightest::before {
    content: "action, lightest"; }
  .is-sample-action-lightest::after {
    content: "#e5edfb"; }

.is-sample-action-base {
  background: #1662dd; }
  .is-sample-action-base::before, .is-sample-action-base::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-action-base::before {
    content: "action, base"; }
  .is-sample-action-base::after {
    content: "#1662dd"; }

.is-sample-action-dark {
  background: #124a94; }
  .is-sample-action-dark::before, .is-sample-action-dark::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-action-dark::before {
    content: "action, dark"; }
  .is-sample-action-dark::after {
    content: "#124a94"; }

.is-sample-yellow-lightest {
  background: #fcf9e1; }
  .is-sample-yellow-lightest::before, .is-sample-yellow-lightest::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-yellow-lightest::before {
    content: "yellow, lightest"; }
  .is-sample-yellow-lightest::after {
    content: "#fcf9e1"; }

.is-sample-yellow-base {
  background: #ebd551; }
  .is-sample-yellow-base::before, .is-sample-yellow-base::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-yellow-base::before {
    content: "yellow, base"; }
  .is-sample-yellow-base::after {
    content: "#ebd551"; }

.is-sample-yellow-dark {
  background: #bc960f; }
  .is-sample-yellow-dark::before, .is-sample-yellow-dark::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-yellow-dark::before {
    content: "yellow, dark"; }
  .is-sample-yellow-dark::after {
    content: "#bc960f"; }

.is-sample-warning-lightest {
  background: #feefe2; }
  .is-sample-warning-lightest::before, .is-sample-warning-lightest::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-warning-lightest::before {
    content: "warning, lightest"; }
  .is-sample-warning-lightest::after {
    content: "#feefe2"; }

.is-sample-warning-base {
  background: #f47c25; }
  .is-sample-warning-base::before, .is-sample-warning-base::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-warning-base::before {
    content: "warning, base"; }
  .is-sample-warning-base::after {
    content: "#f47c25"; }

.is-sample-warning-dark {
  background: #cb5500; }
  .is-sample-warning-dark::before, .is-sample-warning-dark::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-warning-dark::before {
    content: "warning, dark"; }
  .is-sample-warning-dark::after {
    content: "#cb5500"; }

.is-sample-danger-lightest {
  background: #fee6ea; }
  .is-sample-danger-lightest::before, .is-sample-danger-lightest::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-danger-lightest::before {
    content: "danger, lightest"; }
  .is-sample-danger-lightest::after {
    content: "#fee6ea"; }

.is-sample-danger-base {
  background: #dd0744; }
  .is-sample-danger-base::before, .is-sample-danger-base::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-danger-base::before {
    content: "danger, base"; }
  .is-sample-danger-base::after {
    content: "#dd0744"; }

.is-sample-danger-dark {
  background: #b80047; }
  .is-sample-danger-dark::before, .is-sample-danger-dark::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-danger-dark::before {
    content: "danger, dark"; }
  .is-sample-danger-dark::after {
    content: "#b80047"; }

.is-sample-cyan-lightest {
  background: #c6fafa; }
  .is-sample-cyan-lightest::before, .is-sample-cyan-lightest::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-cyan-lightest::before {
    content: "cyan, lightest"; }
  .is-sample-cyan-lightest::after {
    content: "#c6fafa"; }

.is-sample-cyan-base {
  background: #007f9c; }
  .is-sample-cyan-base::before, .is-sample-cyan-base::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-cyan-base::before {
    content: "cyan, base"; }
  .is-sample-cyan-base::after {
    content: "#007f9c"; }

.is-sample-cyan-dark {
  background: #004d6b; }
  .is-sample-cyan-dark::before, .is-sample-cyan-dark::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-cyan-dark::before {
    content: "cyan, dark"; }
  .is-sample-cyan-dark::after {
    content: "#004d6b"; }

.is-sample-violet-lightest {
  background: #f3eafe; }
  .is-sample-violet-lightest::before, .is-sample-violet-lightest::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-violet-lightest::before {
    content: "violet, lightest"; }
  .is-sample-violet-lightest::after {
    content: "#f3eafe"; }

.is-sample-violet-base {
  background: #963fe6; }
  .is-sample-violet-base::before, .is-sample-violet-base::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-violet-base::before {
    content: "violet, base"; }
  .is-sample-violet-base::after {
    content: "#963fe6"; }

.is-sample-violet-dark {
  background: #5A13B4; }
  .is-sample-violet-dark::before, .is-sample-violet-dark::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-violet-dark::before {
    content: "violet, dark"; }
  .is-sample-violet-dark::after {
    content: "#5A13B4"; }

.is-sample-magenta-base {
  background: #c72c86; }
  .is-sample-magenta-base::before, .is-sample-magenta-base::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-magenta-base::before {
    content: "magenta, base"; }
  .is-sample-magenta-base::after {
    content: "#c72c86"; }

.is-sample-gray-000 {
  background: #fafafa; }
  .is-sample-gray-000::before, .is-sample-gray-000::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-gray-000::before {
    content: "gray, 000"; }
  .is-sample-gray-000::after {
    content: "#fafafa"; }

.is-sample-gray-100 {
  background: #e4e5e7; }
  .is-sample-gray-100::before, .is-sample-gray-100::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-gray-100::before {
    content: "gray, 100"; }
  .is-sample-gray-100::after {
    content: "#e4e5e7"; }

.is-sample-gray-200 {
  background: #cdd1d3; }
  .is-sample-gray-200::before, .is-sample-gray-200::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-gray-200::before {
    content: "gray, 200"; }
  .is-sample-gray-200::after {
    content: "#cdd1d3"; }

.is-sample-gray-300 {
  background: #b7bcc0; }
  .is-sample-gray-300::before, .is-sample-gray-300::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-gray-300::before {
    content: "gray, 300"; }
  .is-sample-gray-300::after {
    content: "#b7bcc0"; }

.is-sample-gray-400 {
  background: #a0a7ac; }
  .is-sample-gray-400::before, .is-sample-gray-400::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-gray-400::before {
    content: "gray, 400"; }
  .is-sample-gray-400::after {
    content: "#a0a7ac"; }

.is-sample-gray-500 {
  background: #899298; }
  .is-sample-gray-500::before, .is-sample-gray-500::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-gray-500::before {
    content: "gray, 500"; }
  .is-sample-gray-500::after {
    content: "#899298"; }

.is-sample-gray-600 {
  background: #737d85; }
  .is-sample-gray-600::before, .is-sample-gray-600::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-gray-600::before {
    content: "gray, 600"; }
  .is-sample-gray-600::after {
    content: "#737d85"; }

.is-sample-gray-700 {
  background: #5c6971; }
  .is-sample-gray-700::before, .is-sample-gray-700::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-gray-700::before {
    content: "gray, 700"; }
  .is-sample-gray-700::after {
    content: "#5c6971"; }

.is-sample-gray-800 {
  background: #46545e; }
  .is-sample-gray-800::before, .is-sample-gray-800::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-gray-800::before {
    content: "gray, 800"; }
  .is-sample-gray-800::after {
    content: "#46545e"; }

.is-sample-gray-900 {
  background: #2f3f4a; }
  .is-sample-gray-900::before, .is-sample-gray-900::after {
    position: static;
    padding: 0.375rem;
    background: #ffffff;
    font-size: 0.87497rem;
    font-weight: 400; }
  .is-sample-gray-900::before {
    content: "gray, 900"; }
  .is-sample-gray-900::after {
    content: "#2f3f4a"; }

.sample--modal-container,
.sample--wizard-container {
  position: relative;
  margin-bottom: 6rem; }

.nimatron--rendered .is-link-default {
  color: #1662dd; }

.nimatron--rendered .is-link-default:hover,
.nimatron--rendered .is-link-hover {
  color: #124a94; }

.is-link-visited {
  color: #5A13B4; }

.sample--external-link-icon {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M13.5%2010.5V13C13.5%2013.3%2013.3%2013.5%2013%2013.5H3C2.7%2013.5%202.5%2013.3%202.5%2013V3C2.5%202.7%202.7%202.5%203%202.5H5.5%22%20stroke%3D%22%231662DD%22%20stroke-miterlimit%3D%2210%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M8%208L14%202%22%20stroke%3D%22%231662DD%22%20stroke-miterlimit%3D%2210%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M9.5%201.5H14.5%22%20stroke%3D%22%231662DD%22%20stroke-miterlimit%3D%2210%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M14.5%206.5V1.5%22%20stroke%3D%22%231662DD%22%20stroke-miterlimit%3D%2210%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  vertical-align: middle; }

/* stylelint-disable selector-no-qualifying-type */
code:not([class]) {
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  color: #c72c86;
  font-size: 0.87497rem; }

code[class*='language-'],
pre[class*='language-'] {
  background: none;
  color: #5c6971;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  line-height: 1.5;
  text-align: left;
  word-wrap: normal;
  word-break: normal;
  white-space: pre;
  word-spacing: normal;
  hyphens: none;
  tab-size: 2; }

@media print {
  code[class*='language-'],
  pre[class*='language-'] {
    text-shadow: none; } }

/* Code blocks */
pre[class*='language-'] {
  max-height: 50ch;
  margin: 0 0 1.5rem;
  padding: 1.5rem;
  overflow: auto;
  border: 1px dashed #cdd1d3;
  border-radius: 0 0 3px 3px;
  background-color: #ffffff;
  font-size: 0.87497rem; }
  .nimatron--example pre[class*='language-'] {
    margin: 0; }

:not(pre) > code[class*='language-'] {
  background: #ffffff; }

/* Inline code */
:not(pre) > code[class*='language-'] {
  padding: 0.375rem;
  border-radius: 3px;
  white-space: normal; }

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #899298; }

.token.punctuation {
  color: #2f3f4a; }

.namespace {
  opacity: 0.618; }

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #963fe6; }

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #007f9c; }

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  background: rgba(255, 255, 255, 0.382);
  color: #cb5500; }

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #1662dd; }

.token.function,
.token.class-name {
  color: #dd0744; }

.token.regex,
.token.important,
.token.variable {
  color: #f47c25; }

.token.important,
.token.bold {
  font-weight: 600; }

.token.italic {
  font-style: italic; }

.token.entity {
  cursor: help; }

.is-not-provisional {
  display: none; }

.nimatron {
  display: block;
  min-height: 100vh;
  background-image: linear-gradient(to bottom, #124a94 -12rem, #004d6b 75vh);
  background-repeat: no-repeat; }
  @media only screen and (min-width: 75ch) {
    .nimatron {
      display: grid;
      grid-template-columns: minmax(auto, max-content) minmax(auto, auto); } }

.nimatron--header {
  padding: 1.5rem 3rem;
  color: #ffffff; }

.nimatron--header-title {
  font-size: 0; }

.nimatron--header-logo {
  height: 1.5rem;
  fill: #ffffff; }

.nimatron--nav {
  display: grid;
  grid-template-columns: minmax(auto, auto) minmax(auto, auto);
  column-gap: 1.5rem;
  text-transform: capitalize; }
  @media only screen and (min-width: 75ch) {
    .nimatron--nav {
      display: block; } }

.nimatron--nav-section {
  margin: 0 0 1.5rem; }

.nimatron--nav-heading {
  margin: 0 0 0.75rem;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600; }

.nimatron--nav-list {
  margin: 0 0 1.5rem;
  list-style-type: none;
  font-size: 0.87497rem;
  font-weight: 300; }
  .nimatron--nav-list a {
    position: relative;
    color: #ffffff;
    text-decoration: none; }
    .nimatron--nav-list a::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      transition: border-bottom-color 200ms linear;
      border-bottom: 1px solid;
      border-bottom-color: rgba(255, 255, 255, 0.382); }
    .nimatron--nav-list a:hover::before {
      border-bottom-color: rgba(255, 255, 255, 0.618); }

.nimatron--nav-item {
  margin-bottom: 0.375rem; }

.nimatron--main {
  display: block;
  background-color: #ffffff; }
  @media only screen and (min-width: 75ch) {
    .nimatron--main {
      display: grid;
      grid-template-columns: minmax(auto, 100ch) minmax(auto, auto);
      min-width: 0;
      box-shadow: 0 2px 1rem rgba(0, 0, 0, 0.2); } }
  .nimatron--main h2 {
    position: relative;
    padding-top: 1.5rem; }
    .nimatron--main h2::before {
      content: '';
      position: absolute;
      top: 0;
      left: -3rem;
      width: calc(100% + 3rem + 3rem);
      border-top: 1px solid #e4e5e7; }

.nimatron--article {
  min-width: 0;
  padding: 1.5rem 3rem; }
  .nimatron--article ol:not([class]),
  .nimatron--article ul:not([class]) {
    max-width: 50ch;
    margin: 0 0 1.5rem 0;
    padding: 0 0 0 1.5rem;
    list-style-position: outside; }
    .nimatron--article ol:not([class]) li,
    .nimatron--article ul:not([class]) li {
      margin-bottom: 0.75rem; }
  .nimatron--article ul:not([class]) {
    list-style-type: disc; }
  .nimatron--article ol:not([class]) {
    list-style-type: decimal; }

.nimatron--toc {
  display: none;
  position: relative;
  font-size: 0.87497rem; }
  @media only screen and (min-width: 125ch) {
    .nimatron--toc {
      display: block;
      border-left: 1px solid #e4e5e7; } }

.nimatron--toc-space-holder,
.nimatron--toc-fixed {
  padding: 1.5rem; }

.nimatron--toc-space-holder {
  color: transparent;
  pointer-events: none; }

.nimatron--toc-fixed {
  position: fixed; }

.nimatron--toc-title {
  margin-bottom: 0.75rem;
  font-size: 1rem;
  font-weight: 600; }

.nimatron--toc-list-item {
  margin-bottom: 0; }

.nimatron--toc-list-level-2 {
  margin-bottom: 0.375rem; }

.nimatron--toc-list-level-3 .nimatron--toc-list-child {
  display: none; }

.nimatron--toc-list-link {
  position: relative;
  color: #899298;
  text-decoration: none; }
  .nimatron--toc-list-link::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: border-bottom-color 200ms linear;
    border-bottom: 1px solid;
    border-bottom-color: transparent; }
  .nimatron--toc-list-link:hover::before {
    border-bottom-color: #1662dd; }
  .nimatron--toc-list-link[href='#undefined'] {
    display: none; }
  .nimatron--toc-space-holder .nimatron--toc-list-link {
    color: transparent; }
    .nimatron--toc-space-holder .nimatron--toc-list-link::before {
      display: none; }
  .nimatron--toc-list-level-2 > .nimatron--toc-list-link {
    color: #5c6971;
    font-weight: 600; }
    .nimatron--toc-space-holder .nimatron--toc-list-level-2 > .nimatron--toc-list-link {
      color: transparent; }

.nimatron--example {
  max-width: 100%;
  margin-bottom: 1.5rem; }

.nimatron--rendered {
  padding: 1.5rem;
  border: 1px dashed #cdd1d3;
  border-radius: 3px; }
  .nimatron--rendered:not(:only-child) {
    border-bottom: 0; }
  .nimatron--rendered.is-example-disallowed {
    position: relative;
    border-color: #dd0744; }
    .nimatron--rendered.is-example-disallowed::after {
      content: "This is a Don't.";
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0 0.75rem 0.75rem 0;
      font-size: 0.87497rem;
      font-weight: 600;
      color: #b80047; }

.nimatron--figure {
  margin-bottom: 1.5rem; }

svg {
  max-width: 100%; }

h1:empty {
  margin: 0; }
